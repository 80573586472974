<template>
  <div class="c-app flex-row align-items-center bg-yellow">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font260 text-center" style="margin-top:20px"> <strong>Beaming School</strong></CCol>
        <CCol lg="12" class="text-center text-white" style="margin-bottom:20px"> <strong>Big Data Provider For School.</strong></CCol>
        <CCol lg="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardHeader>
                <i class="ri-user-line font260"></i><strong>สำหรับครู/ เจ้าหน้าที่:</strong>
              </CCardHeader>
              <CCardBody>
                <form id="formTeacherLogin" v-on:submit.prevent="loginTeacher" method="POST">
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><CIcon name="cil-user"
                      /></span>
                    </div>
                    <input
                      type="text"
                      name="txtUsername"
                      class="form-control"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><CIcon name="cil-lock-locked"
                      /></span>
                    </div>
                    <input
                      type="password"
                      name="txtPassword"
                      class="form-control"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <CRow>
                    <CCol col="12" class="text-right">
                      <button type="submit" class="btn btn-black btn-block" :disabled="btnLogin.dis">
                        {{ btnLogin.txt }}
                      </button>
                    </CCol>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <CRow style="margin-top:20px">
        <CCol lg=12 class="text-center">
          <CButton class="btn-yellow" @click="$router.push({ path: './loginStudent' })">ฉันคือนักเรียน/ผู้ปกครอง</CButton>
        </CCol>
      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      darkModal: false,
      btnLogin: {txt: 'Login', dis: false}
    };
  },
  methods: {

    loginTeacher(event) {
      this.uploadingStat = 1;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "get");
      this.btnLogin = {txt:'กำลัง Login...', dis: true}
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          //let strJson = (response.data).toString();
          if(!response.data.userData){
            alert("username หรือ password ไม่ถูกต้อง");
            this.btnLogin = {txt: 'Login', dis: false};
            return false;
          }
          if (response.data.error == true) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            this.$router.push({ path: "../dashboard" });
          }
        });
    },
  },
};
</script>
